import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

import styles from '../../assets/css/aboutImage.module.scss';

const AboutImage = ({ url, ...props }) => {
	const title = useRef();
	const [ imageWidth, setImageWidth ] = useState(0);
	const transition = {
		type: 'tween',
		ease: 'easeInOut'
	};
	const variants = {
		img: {
			initial: {
				opacity: 0
			},
			enter: {
				opacity: 1,
				transition: transition
			},
			leave: {
				opacity: 0,
				transition: transition
			}
		},
		header: {
			initial: {
				y: '100%'
			},
			enter: {
				y: 0,
				transition: transition
			},
			leave: {
				y: '-100%',
				transition: transition
			}
		}
	};
	useEffect(() => {
		const isMobile = window.matchMedia('only screen and (max-width: 992px)').matches;
		const img = new Image();
		img.src = url;
		img.onload = () => {
			if (isMobile) {
				let textWidth = title.current.offsetWidth;
				setImageWidth(textWidth + 5 * (textWidth / 100));
			} else {
				setImageWidth('100%');
			}
			props.setImgLoaded(true);
		};
	}, []);

	return (
		<motion.div className={styles.wrapper} variants={variants.img}>
			<motion.img draggable="false" src={url} style={{ width: imageWidth }} alt="me" />
			<div className={styles.textWrapper} style={{ visibility: 'hidden' }}>
				<motion.h1 variants={variants.header}>ABOUT ME</motion.h1>
			</div>
			<div className={styles.textWrapper} style={{ position: 'absolute', bottom: 0 }}>
				<motion.h1 variants={variants.header} ref={title}>
					ABOUT ME
				</motion.h1>
			</div>
		</motion.div>
	);
};

export default AboutImage;
