import React, { useState, useContext } from "react"
import { motion } from "framer-motion"
import SEO from "../components/seo"
import { Context } from "../context/Context"
import AboutImage from "../components/About/AboutImage"
import TextBlock from "../components/ProjectDetails/TextBlock"
import Socials from "../components/Socials/Socials"
import styles from "../assets/css/about.module.scss"

const getAge = () => {
  const birthday = new Date(2000, 7, 1)
  const ageDifMs = Date.now() - birthday
  const ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

const AboutPage = () => {
  const transition = {
    type: "tween",
    ease: "easeInOut",
  }
  const variants = {
    wrapper: {
      enter: {
        transition: { staggerChildren: 0.03 },
      },
    },
    header: {
      initial: {
        y: "100%",
      },
      enter: {
        y: 0,
        transition: transition,
      },
      leave: {
        y: "-100%",
        transition: transition,
      },
    },
  }
  const [imgLoaded, setImgLoaded] = useState(false)
  let { state } = useContext(Context)

  return (
    <>
      <SEO title="About" />
      <motion.div
        className={styles.wrapper}
        initial="initial"
        animate={imgLoaded ? "enter" : "initial"}
        exit={"leave"}
        variants={variants.wrapper}
      >
        <AboutImage url={state.images.aboutImage} setImgLoaded={setImgLoaded} />
        <div className={styles.textWrapper}>
          <div className={styles.desktopHeader}>
            <motion.h1 variants={variants.header}>ABOUT ME</motion.h1>
          </div>
          <TextBlock
            variants={variants.header}
            text={`Hey there! My name is Valentin Horzinek, I am a ${getAge()} year old web developer with a soft spot for fancy animations, clean UI and cleverly crafted web experiences. After finishing my final year at the technical college in Hollabrunn, Lower Austria I have only been improving on the foundations laid out by my education. Since I finished my civil service in April 2021, I have been working as a junior webdev for Zurich Insurance in Vienna.`}
            setTextPrep={() => {
              return
            }}
          />
          <Socials />
          <div className={styles.legal}>
            <div style={{ overflow: "hidden" }}>
              <motion.div variants={variants.header}>
                Legal information:
              </motion.div>
            </div>
            <div style={{ overflow: "hidden" }}>
              <motion.div variants={variants.header}>
                Valentin Horzinek
              </motion.div>
            </div>
            <div style={{ overflow: "hidden" }}>
              <motion.div variants={variants.header}>Web Developer</motion.div>
            </div>
            <div style={{ overflow: "hidden" }}>
              <motion.div variants={variants.header}>
                Stockerau, Lower Austria
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default AboutPage
