import React from "react"
import { FaInstagram, FaLinkedinIn } from "react-icons/fa"
import { motion } from "framer-motion"

import styles from "../../assets/css/socials.module.scss"

const Socials = () => {
  const transition = {
    type: "tween",
    ease: "easeInOut",
  }
  const variants = {
    initial: {
      y: "100%",
    },
    enter: {
      y: 0,
      transition: transition,
    },
    leave: {
      y: "-100%",
      transition: transition,
    },
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.linkWrapper}>
        <motion.a
          href={"https://www.instagram.com/hzkcs/"}
          className={styles.link}
          variants={variants}
        >
          <FaInstagram />
          Instagram
        </motion.a>
      </div>
      <div className={styles.linkWrapper}>
        <motion.a
          href={"https://www.linkedin.com/in/valentin-horzinek-a4161015a/"}
          className={styles.link}
          variants={variants}
        >
          <FaLinkedinIn />
          LinkedIn
        </motion.a>
      </div>
    </div>
  )
}

export default Socials
